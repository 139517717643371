import { ContentState, ContentBlock } from 'draft-js';

export const LinkDecoratorType = 'LINK';

export const LinkDecorator = (props: React.PropsWithChildren<{ contentState: ContentState, entityKey: string }>) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} target='_blank'
            title={`${url}
Ctrl+click open url in new tab.`}
            onClick={(e) => { e.ctrlKey && window.open(url, "_blank"); }} >
            {props.children}
        </a>
    );
};

export const findLinkDecoratorEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === LinkDecoratorType
            );
        },
        callback
    );
}
