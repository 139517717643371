import './RichTextInput.css';
import React from 'react';
import { IFormInputProps } from '../../interfaces/IFormInputProps';
import { IFormInputComponent } from "../../interfaces/IFormInputComponent";
import { RichTextControl } from './RichTextControl';

type RichTextInputProps = IFormInputProps<string | null, HTMLTextAreaElement> & {
    placeholder?: string;
}

export default class RichTextInput extends React.Component<RichTextInputProps> implements IFormInputComponent {
    constructor(props: RichTextInputProps) {
        super(props);

    }

    render() {
        return <div className={`richtext-wrapper ${this.props.className || ''}`} >
            <RichTextControl {...this.props} />
        </div>;
    }

    public focus() {

    }

//     <div className="editor" >
//     <Editor
//         ref={ref => this.domEditor = ref}
//         readOnly={this.props.readOnly}
//         placeholder={this.props.placeholder}
//         editorState={this.state.editorState}
//         handleKeyCommand={this.handleKeyCommand}
//         keyBindingFn={this.keyBindingFn}
//         onChange={this.onChange}
//         onBlur={this.props.readOnly
//             ? undefined
//             : () => this.props.onEditComplete?.(getHtml(this.state.editorState))}
//     />
// </div>


    // private onChange = (editorState: EditorState) => {
    //     if (this.props.readOnly) return; 

    //     this.setState({ editorState });
    //     this.props.onChanged?.(getHtml(editorState));
    // }
}