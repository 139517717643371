import './RichTextControl.css';
import 'draft-js/dist/Draft.css';
import React from 'react';
import {
    EditorState, Editor, RichUtils, convertFromHTML, ContentState, convertToRaw, getDefaultKeyBinding, EditorCommand,
    DraftHandleValue, KeyBindingUtil, Modifier, CompositeDecorator
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { IFormInputProps } from '../../interfaces/IFormInputProps';
import { colorStyleMap, RichTextInputToolbar } from './RichTextInputToolbar';
import { findLinkDecoratorEntities, LinkDecorator, LinkDecoratorType } from './LinkDecorator';

type RichTextInputProps = IFormInputProps<string | null, HTMLTextAreaElement> & {
    placeholder?: string;
}

export const RichTextControl = (props: RichTextInputProps) => {
    let domEditor = React.useRef<Editor | null>(null);
    const [editorState, setEditorState] = React.useState<EditorState>(() => {
        const decorator = new CompositeDecorator([
            {
                strategy: findLinkDecoratorEntities,
                component: LinkDecorator,
            },
        ]);

        if (!props.value) {
            return EditorState.createEmpty(decorator);
        }

        const blocksFromHTML = convertFromHTML(props.value);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        return EditorState.createWithContent(state, decorator);
    });

    const onChange = React.useCallback((state: EditorState) => {
        if (props.readOnly) return;

        setEditorState(state);
        props.onChanged?.(getHtml(state));
    }, [editorState]);
    
    const focus = React.useCallback(() => {
        setTimeout(() => domEditor.current?.focus(), 100);
    }, [domEditor]);

    const onCommand = React.useCallback((command: string, data?: any) => {
        if (props.readOnly) return;

        switch (command) {
            case "bold":
            case "underline":
            case "italic":
            case "strikethrough":
                setEditorState(RichUtils.toggleInlineStyle(editorState, command.toUpperCase()));
                break;
            case "header-one":
            case "header-two":
            case "header-three":
            case "header-four":
            case "header-five":
            case "header-six":
                setEditorState(RichUtils.toggleBlockType(editorState, command));
                break;
            case "ul":
                setEditorState(RichUtils.toggleBlockType(editorState, "unordered-list-item"));
                break;
            case "ol":
                setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
                break;
            case "link":
                const contentStateWithEntity = editorState.getCurrentContent().createEntity(LinkDecoratorType, 'MUTABLE', { url: data });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const linkEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
                setEditorState(RichUtils.toggleLink(linkEditorState, linkEditorState.getSelection(), entityKey))
                break;
            case "clear-format":
                const clear_selection = editorState.getSelection();

                // remove font size
                const cleared_content1 = Modifier.setBlockType(editorState.getCurrentContent(), clear_selection, "unstyled");
                let state = EditorState.push(editorState, cleared_content1, 'change-block-type');

                // remove font markers
                const cleared_content2 = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', ...Object.keys(colorStyleMap)]
                    .reduce((contentState, color) => {
                        return Modifier.removeInlineStyle(contentState, clear_selection, color)
                    }, state.getCurrentContent());
                state = EditorState.push(state, cleared_content2, 'change-inline-style')

                setEditorState(state);
                break;
            case "color":
                const toggledColor = data;
                const colorSelection = editorState.getSelection();

                const colorContentState = Object.keys(colorStyleMap)
                    .reduce((contentState, color) => {
                        return Modifier.removeInlineStyle(contentState, colorSelection, color)
                    }, editorState.getCurrentContent());
                let colorEditorState = EditorState.push(editorState, colorContentState, 'change-inline-style');

                if (toggledColor !== "transparent") {
                    const currentStyle = editorState.getCurrentInlineStyle();

                    if (colorSelection.isCollapsed()) {
                        colorEditorState = currentStyle.reduce((state, color) => {
                            return RichUtils.toggleInlineStyle(state!, color!);
                        }, colorEditorState);
                    }

                    if (!currentStyle.has(toggledColor)) {
                        colorEditorState = RichUtils.toggleInlineStyle(colorEditorState, toggledColor);
                    }
                }
      
                setEditorState(colorEditorState);
                break;
            case "highlight":
                break;
            default:
                console.error(`Unknown command: "${command}"`, data);
                return;
        }

        focus();
    }, [props.readOnly, editorState]);

    const handleKeyCommand = React.useCallback((command: EditorCommand, state: EditorState): DraftHandleValue => {
        switch (command) {
            case 'bold':
            case 'underline':
            case 'italic':
            case 'ul':
            case 'ol':
            case 'header-one':
            case 'header-two':
            case 'header-three':
            case 'header-four':
            case 'header-five':
            case 'header-six':
            case 'header-one':
                onCommand(command);
                return 'handled';

            default:
                return 'not-handled';
        }
    }, [props.readOnly, editorState]);

    const keyBindingFn = React.useCallback((e: React.KeyboardEvent<{}>): EditorCommand | null => {
        if (KeyBindingUtil.isCtrlKeyCommand(e) && e.keyCode === 190) {
            return 'ul';
        }

        if (KeyBindingUtil.isCtrlKeyCommand(e) && e.keyCode === 191) {
            return 'ol';
        }

        if (e.altKey && e.ctrlKey && e.keyCode === 49) {
            return 'header-one';
        }
        if (e.altKey && e.ctrlKey && e.keyCode === 50) {
            return 'header-two';
        }
        if (e.altKey && e.ctrlKey && e.keyCode === 51) {
            return 'header-three';
        }
        if (e.altKey && e.ctrlKey && e.keyCode === 52) {
            return 'header-four';
        }
        if (e.altKey && e.ctrlKey && e.keyCode === 53) {
            return 'header-five';
        }
        if (e.altKey && e.ctrlKey && e.keyCode === 54) {
            return 'header-six';
        }

        return getDefaultKeyBinding(e);
    }, [props.readOnly, editorState]);

    const showToolbar = !props.readOnly && !editorState.getSelection().isCollapsed();

    return <div className={`richtext ${props.className || ''}`} >
        {showToolbar && <RichTextInputToolbar editorState={editorState} onCommand={onCommand} />}
        <div className="editor">
            <Editor
                ref={domEditor}
                readOnly={props.readOnly}
                placeholder={props.placeholder}
                editorState={editorState}
                customStyleMap={colorStyleMap}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={keyBindingFn}
                onChange={onChange}
                onBlur={props.readOnly
                    ? undefined
                    : () => props.onEditComplete?.(getHtml(editorState))}
            />
        </div>
    </div>;
}

const getHtml = (editorState: EditorState): string => draftToHtml(convertToRaw(editorState.getCurrentContent()));
