import './RichTextInput.css';
import 'draft-js/dist/Draft.css';
import React, { useCallback, useMemo } from 'react';
import { IButtonProps, IconButton, IContextualMenuItem, ITextField, Stack, TextField } from 'office-ui-fabric-react';
import { EditorState } from 'draft-js';
import { Dictionary } from '../../../../entities/common';
import { Validator } from '../../../../validation';

//export const RICHTEXT_MULTILINE_MAX_LENGTH = 8000;

type ButtonsSettingType = {
    label?: string,
    text?: string,
    iconName?: string,
    style?: string,
    title?: string,
    command: string,
};

const ButtonsSettings: Dictionary<ButtonsSettingType> = {
    Bold: {
        label: 'Bold',
        iconName: 'Bold',
        style: 'BOLD',
        title: "Bold (Ctrl+B)",
        command: 'bold'
    },
    Italic: {
        label: 'Italic',
        iconName: 'Italic',
        style: 'ITALIC',
        title: "Italic (Ctrl+I)",
        command: 'italic',
    },
    Underline: {
        label: 'Underline',
        iconName: 'Underline',
        style: 'UNDERLINE',
        title: "Underline (Ctrl+U)",
        command: 'underline',
    },
    Strikethrough: {
        label: 'Strikethrough',
        iconName: 'Strikethrough',
        style: 'STRIKETHROUGH',
        title: "Strikethrough (Alt+Ctrl+S)",
        command: 'strikethrough',
    },
    h1: {
        text: 'Header 1',
        iconName: 'Header1',
        title: "Header 1 (Ctrl+Alt+1)",
        style: 'header-one',
        command: 'header-one',
    },
    h2: {
        text: 'Header 2',
        iconName: 'Header2',
        title: "Header 2 (Ctrl+Alt+2)",
        style: 'header-two',
        command: 'header-two',
    },
    h3: {
        text: 'Header 3',
        iconName: 'Header3',
        title: "Header 3 (Ctrl+Alt+3)",
        style: 'header-three',
        command: 'header-three',
    },
    h4: {
        text: 'Header 4',
        iconName: 'Header4',
        title: "Header 4 (Ctrl+Alt+4)",
        style: 'header-four',
        command: 'header-four',
    },
    h5: {
        text: 'Header 5',
        iconName: 'Header5',
        title: "Header 1 (Ctrl+Alt+5)",
        style: 'header-five',
        command: 'header-five',
    },
    h6: {
        text: 'Header 6',
        iconName: 'Header6',
        title: "Header 1 (Ctrl+Alt+6)",
        style: 'header-six',
        command: 'header-six',
    },
    
    Blockquote: {
        label: 'Blockquote',
        style: 'blockquote',
        command: 'blockquote'
    },
    code: {
        label: 'Code Block',
        title: '',
        iconName: undefined,
        style: 'code-block',
        command: 'code-block'
    },

    ul: {
        label: 'UL',
        iconName: 'BulletedList',
        title: "Bulleted list (Ctrl+.)",
        style: 'unordered-list-item',
        command: 'ul',
    },
    ol: {
        label: 'OL',
        iconName: 'NumberedList',
        title: "Numbered list (Ctrl+/)",
        style: 'ordered-list-item',
        command: 'ol',
    },

    clear: {
        label: 'ClearFormatting',
        iconName: 'ClearFormatting',
        title: "Clear format (Ctrl+Q)",
        command: 'clear-format',
    },
};

type RichTextInputToolbarProps = {
    editorState: EditorState,
    onCommand: (command: string, data?: any) => void,
}

export const RichTextInputToolbar = (props: RichTextInputToolbarProps) => {
    const [showLinkEditor, setShowLinkEditor] = React.useState(false);
    const [urlValue, setUrlValue] = React.useState<string | undefined>();

    const getToolbarCommands = (editorState: EditorState): IContextualMenuItem[] => {
        const currentStyle = editorState.getCurrentInlineStyle();
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        const fontSizeItems = [ButtonsSettings.h1, ButtonsSettings.h2, ButtonsSettings.h3, ButtonsSettings.h4];
        return [
            {
                key: "btnFontStyle",
                iconProps: { iconName: fontSizeItems.find(_ => _.style === blockType)?.iconName ?? "FontSize" },
                menuProps: {
                    items: fontSizeItems
                        .map(_ => ({
                            ...buildMenuItem(_),
                            iconProps: { iconName: _.style === blockType ? "CheckMark" : _.iconName },
                            onClick: () => props.onCommand(_.command)
                        })),
                }
            },
            { key: "separator" },
            ...[ButtonsSettings.Bold, ButtonsSettings.Italic, ButtonsSettings.Underline, ButtonsSettings.Strikethrough]
                .map(_ => ({
                    ...buildMenuItem(_),
                    checked: currentStyle.has(_.style!),
                    onClick: () => props.onCommand(_.command)
                })),
            { key: "separator" },
            {
                key: "btnFontColor",
                iconProps: { iconName: "FontColor" },
                menuProps: {
                    items: [
                        ...[...Array(24).keys()]
                        .map<IContextualMenuItem>(i => {
                            const colorCode = `color${i}`;
                            return {
                                key: colorCode,
                                onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) =>
                                    <span className='color-button'
                                        style={{ backgroundColor: colorStyleMap[colorCode].color }}
                                        onClick={(e) => {
                                            dismissMenu(e, true);
                                            props.onCommand("color", colorCode);
                                        }} >
                                            {currentStyle.has(colorCode) ? '\u2714' : ''}
                                    </span>
                            };
                        }),
                        {
                            key: "reset-color",
                            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) =>
                                <span className='reset-color-button'
                                    onClick={(e) => {
                                        dismissMenu(e, true);
                                        props.onCommand("color", "transparent");
                                    }} >Remove color</span>
                        }
                    ]
                }
            },
            { key: "separator" },
            {
                ...buildMenuItem(ButtonsSettings.ul),
                checked: ButtonsSettings.ul.style === blockType,
                onClick: () => props.onCommand(ButtonsSettings.ul.command)
            },
            {
                ...buildMenuItem(ButtonsSettings.ol),
                checked: ButtonsSettings.ol.style === blockType,
                onClick: () => props.onCommand(ButtonsSettings.ol.command)
            },
            { key: "separator" },
            {
                key: "btnLink",
                iconProps: { iconName: "Link" },
                title: 'Insert a hyperlink (Ctrl+K)',
                onClick: () => {
                    const selection = editorState.getSelection();
                    if (!selection.isCollapsed()) {
                        const contentState = editorState.getCurrentContent();
                        const startKey = editorState.getSelection().getStartKey();
                        const startOffset = editorState.getSelection().getStartOffset();
                        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

                        setUrlValue(linkKey ? contentState.getEntity(linkKey).getData().url : '');
                        setShowLinkEditor(true);
                    }
                },
            },
            { key: "separator" },
            {
                ...buildMenuItem(ButtonsSettings.clear),
                onClick: () => props.onCommand(ButtonsSettings.clear.command)
            },
        ];
    }

    const commands = getToolbarCommands(props.editorState);

    return <>
        {showLinkEditor
            && <div className='toolbar'>
                <LinkEditor
                    value={urlValue}
                    onClose={() => setShowLinkEditor(false)}
                    onComplete={(url: string) => props.onCommand("link", url)}
                /></div>}
        {!showLinkEditor && !!commands?.length
            && <Stack className='toolbar' horizontal>
                {commands.map((_, index) => _.key === "separator"
                    ? <div key={`sep${index}`} className="separator" />
                    : <IconButton key={`btn${index}`}
                        style={{
                            height: 24,
                        }}
                        {..._ as IButtonProps}
                        onClick={(ev) => { ev.stopPropagation(); _.onClick?.(); }}
                    />
                )}
            </Stack>}
    </>;
}

const buildMenuItem = (_: ButtonsSettingType): IContextualMenuItem => ({
    key: `btn${_.style}`,
    text: _.text,
    iconProps: { iconName: _.iconName },
    title: _.title,
});

export const LinkEditor = (props: {
    value?: string;
    onComplete: (url: string) => void;
    onClose: () => void;
}) => {
    const [value, setValue] = React.useState(props.value);
    const inputRef = React.useRef<ITextField | null>(null);

    const focus = useCallback(() => inputRef.current?.focus(), [inputRef])

    const validator = useMemo(() => Validator.new()/*.required().url()*/.build(), []);

    React.useEffect(focus, []);

    return <TextField className='link-editor'
        value={value}
        componentRef={inputRef}
        onGetErrorMessage={(value: string) => validator.getErrorMessage(value)}
        placeholder='Type URL then press Enter or Escape for exit'
        onChange={(e, val) => setValue(val)}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();

                if (validator.isValid(value)) {
                    if (value !== props.value) {
                        props.onComplete(value!);
                    }
                    props.onClose();
                }
            }
            if (e.key === 'Escape') {
                e.preventDefault();
                props.onClose();
            }
        }}
        onBlur={focus}
    />;
}

export const colorStyleMap: any = {
    color0: {
        color: '#A80000',
    },
    color1: {
        color: '#EB0716',
    },
    color2: {
        color: '#E54917',
    },
    color3: {
        color: '#FF6C3D',
    },
    color4: {
        color: '#FF8C00',
    },
    color5: {
        color: '#FFBA00',
    },
    color6: {
        color: '#FFDA44',
    },
    color7: {
        color: '#FFDA44',
    },
    color8: {
        color: '#28AA32',
    },
    color9: {
        color: '#128A10',
    },
    color10: {
        color: '#008272',
    },
    color11: {
        color: '#00B394',
    },
    color12: {
        color: '#00D9B1',
    },
    color13: {
        color: '#00BBF5',
    },
    color14: {
        color: '#0076D7',
    },
    color15: {
        color: '#4665D4',
    },
    color16: {
        color: '#6446CF',
    },
    color17: {
        color: '#8271EB',
    },
    color18: {
        color: '#B49DFF',
    },
    color19: {
        color: '#B600A0',
    },
    color20: {
        color: '#E6008C',
    },
    color21: {
        color: '#FF6BB4',
    },
    color22: {
        color: '#788086',
    },
    color23: {
        color: '#BDBDBD',
    },        
};