import * as React from "react";
import { Dictionary } from "../../entities/common";
import { Toggle, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { FieldInfo } from "../../entities/Metadata";
import { ISettingsEditComponent, IBaseFieldInfo, FieldActionTypes } from "./FieldPanel";
import LabellableComponent from "../common/LabellableComponent";

type Props = {
    isNative: boolean;
    settings?: Dictionary<any>;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
}

type State = {
    multiline: boolean;
    switchedToMultiline?: boolean;
    richtext: boolean;
    switchedToRichtext?: boolean;
}

export class TextSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    constructor(props: Props) {
        super(props);
        this.state = {
            multiline: props.settings ? props.settings['multiline'] == true : false,
            richtext: props.settings ? props.settings['richtext'] == true : false
        };
    }

    public render() {
        const { multiline, switchedToMultiline, richtext, switchedToRichtext } = this.state;
        const { actionType, isNative, settings } = this.props;

        return <>
            <Toggle label='Multiline'
                key='toggle'
                checked={multiline}
                disabled={isNative || actionType == FieldActionTypes.Edit && settings?.['multiline']}
                onChange={(e, checked) => { this.setState({ multiline: !!checked, switchedToMultiline: !!checked }); this.props.onChange(true); }}
                onText='Yes'
                offText='No' />
            {switchedToMultiline && <MessageBar key="msgbar" messageBarType={MessageBarType.warning}>
                Note, if you enable Multiline option, you won't be able to change it back later.
            </MessageBar>}

            {multiline && <>
                <LabellableComponent  
                    className="field-container"
                    label='Rich Text'
                    description={"Rich Text field allows you to format your text with various styles, including bold, italics, headings, lists, links, and more to make your content visually engaging and well-structured."}>
                    <Toggle
                        key='toggle'
                        checked={richtext}
                        disabled={isNative || actionType == FieldActionTypes.Edit && settings?.['richtext']}
                        onChange={(e, checked) => { this.setState({ richtext: !!checked, switchedToRichtext: !!checked }); this.props.onChange(true); }}
                        onText='Yes'
                        offText='No' />
                </LabellableComponent>
                {switchedToRichtext && <MessageBar key="msgbar" messageBarType={MessageBarType.warning}>
                    Note, if you enable Rich Text option, you won't be able to change it back later.
                </MessageBar>}
            </>}
        </>;
    }

    public save(fieldInfo: FieldInfo) {
        this._applyChanges(fieldInfo);
    }

    public update(fieldInfo: IBaseFieldInfo) {
        this._applyChanges(fieldInfo);
    }

    private _applyChanges(fieldInfo: IBaseFieldInfo | FieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            multiline: this.state.multiline,
        };
        if (this.state.richtext) {
            fieldInfo.settings = {
                ...fieldInfo.settings,
                richtext: this.state.richtext,
            };
        }
    }
}
